import { isEqual } from 'lodash';

export class ArrayUtils {
  public static remove<T>(array: Array<T>, value: T): void {
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i] === value) {
        array.splice(i, 1);
      }
    }
  }

  /**
   * only pushes the value into the array if it doesn't already exist in there
   */
  public static pushUnique<T>(array: Array<T>, value: T): void {
    const index = array.indexOf(value);
    if (index === -1) {
      array.push(value);
    }
  }

  /**
   * Returns an array where all values are unique and distinct from one another.
   *
   * @example
   * const uniq = ArrayUtils.unique([1, 2, 3, 3, 3, 4]);
   * expect(uniq).to.deep.eq([1, 2, 3, 4]);
   */
  public static unique<T>(arr: Array<T>): Array<T>;
  /**
   * Returns an array where all values are unique and distinct from one another.
   * `id` is used to retrieve a value the items can be compared on.
   *
   * @example
   * const uniq = ArrayUtils.unique([{ id: "1", a: 1 }, { id: "2", b: 2 }, { id: "1", c: 3 }], item => item.id);
   * expect(uniq).to.deep.eq([{ id: "1", c: 3 }, { id: "2", b: 2 }]);
   */
  public static unique<T>(arr: Array<T>, id: (item: T) => any): Array<T>;
  public static unique<T>(
    arr: Array<T>,
    id: (item: T) => any = (t) => t
  ): Array<T> {
    const map = new Map(arr.map((item) => [id(item), item]));
    return [...map.values()];
  }

  /**
   * NOTE: Order of provided arrays will be modified in-place
   * Equality check relies on sorting => only primitive types string|number are supported
   */
  public static equalIgnoringOrder(
    array1: Array<string | number>,
    array2: Array<string | number>
  ): boolean {
    return isEqual(array1.sort(), array2.sort());
  }

  public static findOccurence<T>(
    array: Array<T>,
    occurrence: number,
    checkFunction: (item: T) => boolean
  ): T | null {
    if (occurrence < 1) {
      throw new Error('invalid occurrence: ' + occurrence);
    }

    let currentOccurence = 0;

    for (const item of array) {
      if (checkFunction(item)) {
        currentOccurence++;

        if (currentOccurence === occurrence) {
          return item;
        }
      }
    }

    return null;
  }

  public static computeArrayElementDifferences<T, U>(
    array1: Array<T>,
    array2: Array<U>,
    compareFunction: (item1: T, item2: U) => boolean
  ): {
    onlyInArray1: Array<T>;
    onlyInArray2: Array<U>;
    inBothArrays: Array<{ item1: T; item2: U }>;
  } {
    const array1Copy = array1.slice();
    const array2Copy = array2.slice();

    const onlyInArray1: Array<T> = [];

    const inBothArrays: Array<{ item1: T; item2: U }> = [];

    while (array1Copy.length) {
      const item1 = array1Copy.shift();
      if (item1 == null) continue;

      const foundItem2Index = array2Copy.findIndex((item2) => {
        return compareFunction(item1, item2);
      });

      if (foundItem2Index >= 0) {
        const items = array2Copy.splice(foundItem2Index, 1);

        const item2 = items[0];
        if (!item2) throw new Error('item2 not found');

        inBothArrays.push({ item1, item2 });
      } else {
        onlyInArray1.push(item1);
      }
    }

    return {
      onlyInArray1,
      onlyInArray2: array2Copy,
      inBothArrays
    };
  }

  public static batch<T>(array: Array<T>, batchSize: number): Array<Array<T>> {
    if (batchSize < 1)
      throw new Error('batch size must be greater or equal to 1');

    const batchedArray: Array<Array<T>> = [];

    for (let i = 0; i < array.length; i += batchSize) {
      batchedArray.push(array.slice(i, i + batchSize));
    }

    return batchedArray;
  }

  public static trim<T>(
    array: Array<T>,
    predicate: (item?: T) => boolean = Boolean
  ): Array<T> {
    const arrayCopy = array.slice();

    while (arrayCopy.length && !predicate(arrayCopy.at(0))) {
      arrayCopy.splice(0, 1);
    }

    while (arrayCopy.length && !predicate(arrayCopy.at(-1))) {
      arrayCopy.splice(-1, 1);
    }

    return arrayCopy;
  }

  public static groupBy<T, U extends string | number | symbol>(
    arr: Array<T>,
    fn: (item: T) => U
  ): Record<U, Array<T>> {
    const ret: Record<U, Array<T>> = {} as any;
    for (const item of arr) {
      const key = fn(item);
      ret[key] = [...(ret[key] ?? []), item];
    }
    return ret;
  }

  public static groupByBoolean<T>(
    arr: Array<T>,
    fn: (item: T) => boolean
  ): { true: Array<T>; false: Array<T> } {
    const booleanKeyFn = (item: T): 'true' | 'false' =>
      String(fn(item)) as 'true' | 'false';
    return this.groupBy(arr, booleanKeyFn);
  }
}
